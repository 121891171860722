<template>
  <vx-card>
    <div class="vx-row ml-2 mb-2">
      <h4>Collection Plan</h4>
    </div>

    <div class="vx-row my-4">
      <div class="vx-col">
        <vs-button
          class="mt-2"
          color="primary"
          type="border"
          icon-pack="feather"
          icon="icon-plus"
          @click="handleOnRoute"
          >On Route</vs-button
        >
      </div>
      <div class="vx-col">
        <vs-button
          class="mt-2"
          color="primary"
          type="border"
          icon-pack="feather"
          icon="icon-plus"
          @click="handleOffRoute"
          >Off Route</vs-button
        >
      </div>
    </div>

    <div class="vx-row my-4">
      <div class="vx-col">
        <vs-button
          class="mt-2"
          color="warning"
          icon-pack="feather"
          icon="icon-download"
          @click="handleExport('excel')"
          >Multiple Export to Excel</vs-button
        >
      </div>
      <div class="vx-col">
        <vs-button
          class="mt-2"
          color="primary"
          icon-pack="feather"
          icon="icon-printer"
          @click="handleExport('pdf')"
          >Multiple Export to Pdf</vs-button
        >
      </div>
    </div>

    <hr/>
    <div class="vx-row mb-6">
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Date</label>
        <vs-input
          class="w-full"
          v-model="date"
          type="date"
          v-validate="'required'"
        />
      </div>
    </div>
    <select-territory @data="setTerritoryId"></select-territory>

    <div class="vx-row mb-6">
      <div class="vx-col flex items-center justify-items-start gap-2">
        <vs-button
          @click="onClickSearch"
          size="small"
          color="primary"
          icon-pack="feather"
          icon="icon-search"
          title="Search"
        >
          Browse</vs-button
        >
      </div>
    </div>

    <vs-tabs>
      <vs-tab label="Released">
        <div class="tab-text">
          <released-index ref="table" @data="getTableIds" :date="date" :territoryID="territoryId"></released-index>
        </div>
      </vs-tab>
      <vs-tab label="Wait Approval">
        <div class="tab-text">
          <wait-approval-index
            ref="table"
            @data="getTableIds"
            :date="date" :territoryID="territoryId"
          ></wait-approval-index>
        </div>
      </vs-tab>
      <vs-tab label="Rejected">
        <div class="tab-text">
          <canceled-index ref="table" @data="getTableIds" :date="date" :territoryID="territoryId"></canceled-index>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import ReleasedIndex from "./_tabs/released/index";
import CanceledIndex from "./_tabs/canceled/index";
import WaitApprovalIndex from "./_tabs/wait-approval/index";
import SelectTerritory from "./select-territory.vue";

export default {
  components: {
    ReleasedIndex,
    WaitApprovalIndex,
    CanceledIndex,
    SelectTerritory
  },
  data() {
    return {
      tableIds: [],
      date: "",
      territoryId: "",
      territoryCode: "",
    };
  },
  methods: {
    onClickSearch() {
      if (this.$refs.table) {
        this.$refs.table.setDate(this.date)
        this.$refs.table.setTerritoryId(this.territorryId)
      }

      // reload table data
      this.$refs.table.getData()

      this.$vs.notify({
        title: "Success",
        text: "Browse data success",
        color: "success",
        position: "top-right",
      });
    },
    setTerritoryId(id, code) {
      this.territorryId = id;
      this.territoryCode = code;
    },
    handleOnRoute() {
      this.$router.push({ name: "collection-plan-new-on-route" });
    },
    handleOffRoute() {
      this.$router.push({ name: "collection-plan-new-off-route" });
    },

    handleExport(type) {
      if (this.tableIds.length > 0) {
        if (type == "pdf") {
          this.$refs.table.doMultipleExportPdf(type, this.tableIds);
        } else if (type == "excel") {
          this.$refs.table.doMultipleExport(type, this.tableIds);
        }
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select at least one data",
          color: "danger",
        });
      }
    },
    getTableIds(data) {
      this.tableIds = data;
    },
  },
};
</script>
